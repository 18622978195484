import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class EditDataService {
  private _data = null;
  constructor() { }

  public getData() {
    return this._data;
  }

  public setData(data:any) {
    this._data = data;
  }
}