import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { LogErrorsService } from './log-errors.service';

@Injectable({
  providedIn: 'root'
})
export class VerifyFibexplayService {
  public fibex_play : boolean = true;
  constructor(
    private _http: HttpClient,
    private _logs: LogErrorsService, 
  ) { }
  async handleFibexPlay(cedula: string) {
    try {
      const response: any= await this._http.get(`${environment.apiFibexPlay}cedula=${cedula}`).toPromise();
      if (response.status) {
        this.fibex_play = response.fibex_play === "1" ? true : false;
      } else {
        this.fibex_play = false;
      }
      return { 
        error: false, 
        data: response
      }
    } catch (error) {
      this._logs.insertarError(error.message);
      this.fibex_play = true;
     return {
      error: true, 
      data: null
     }
    }
  }
}
