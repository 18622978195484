import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { LogErrorsService } from './log-errors.service';

@Injectable({
  providedIn: 'root'
})
export class HotspotService {

  constructor(
    public http: HttpClient,
    private _logs: LogErrorsService, 
  ) { }

  async AddContact(nombre: string, cedula:string, phone:string, ubicacion:string, email: string, message: string) { 
    const data =  [ { property: 'email', value: email },
    { property: 'work_email', value: email },   
    { property: 'correo_electrnico', value: email },   
    { property: 'firstname', value: nombre },
    { property: 'lastname', value: '(Referido APP)' },
    { property: 'website', value: 'https://fibextelecom.net/' },        
    { property: 'company', value: 'FibexTelecom' },
    { property: 'phone', value: phone}, 
    { property: 'mobilephone', value: phone},
    { property: 'fuente_lead', value: 'THOMAS'}, 
    { property: 'country', value: 'Venezuela'}, 
    //nota
    { property: 'message', value: `${message} ${cedula}`}, 
    { property: 'nro__ab_saeplus', value: '0'}, 
    //ubicacion
    { property: 'address', value: ubicacion },
    { property: 'hs_timezone', value: 'america_slash_caracas'},
    //ubicacion
    { property: 'city', value: ubicacion },
    { property: 'state', value: 'MA' }  ]
    const query = data.reduce((str, item) => str+`${item.property}=${item.value}&`,"").slice(0, -1)
    try {
      const hubResponse:any = await this.http.get(`${environment.hotspotApi}?${query}`).toPromise();
      if (hubResponse.error) {
        return {
          error: true,
          data: null,
          message: hubResponse.errors[0].message
        };
      }

      return {
        error: false,
        data: hubResponse,

      };
    } catch (error) {
      this._logs.insertarError(error.message);
      return {
        error: true,
        data: null,
        message: error.message
      };
    }
       
  }

  

}
